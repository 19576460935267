.addIcon {
  background: rgb(36, 117, 84);
  width: 42px;
  height: 42px;
  margin-top: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  border-radius: 4px;
  transition: 0.3s;
}

.addIcon:hover {
  background: rgb(36, 117, 84);
  transition: 0.3s;
}

.removeIcon {
  background: rgb(36, 117, 84);
  width: 42px;
  height: 42px;
  margin-top: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  border-radius: 4px;
  transition: 0.3s;
}

.removeIcon:hover {
  background: rgb(36, 117, 84);
  transition: 0.3s;
}
