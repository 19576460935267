.tz-bold {
    font-weight: bold;
    padding-left: 10px;
}

.tz-cat-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.data-table-extensions>.data-table-extensions-action>button.download:hover::after {
    content: ""
}

.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
    height: 18px;
    width: 18px;
}

.tz-form-input-center {
    display: flex;
    align-items: "center";
}

.list-item-bold span {
    color: #000;
    text-transform: capitalize;
}